@import '~bootstrap/scss/tables';

.row-border {
  & > *:first-child {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0.5px;
      bottom: 0.5px;
      height: calc(100% - 1px);
      width: 0px;
      border-right: solid 0.25rem white;
    }
  }

  &.-left {
    & > *:first-child {
      &::before {
        left: 0;
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .row-border-#{$color} {
    & > *:first-child {
      &::before {
        border-color: $value;
      }
    }
  }
}
