@import 'scss/for-module';

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  flex: 1 auto;
  height: 100%;

  &__inner {
    flex: 1 auto;
  }
}
