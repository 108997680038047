.accordion {
  $accordion-padding-y: 1.25rem;
  $accordion-padding-x: 3.25rem;
  --accordion-padding-x: #{$accordion-padding-x};
  --accordion-padding-y: #{$accordion-padding-y};

  @include media-breakpoint-down('md') {
    $accordion-padding-x: 3.25rem;
    --accordion-padding-x: #{$accordion-padding-x};
  }

  &.gap-x-lg {
    --accordion-padding-x: #{$accordion-padding-x * 1.5};

    @include media-breakpoint-down('md') {
      --accordion-padding-x: #{$accordion-padding-x * 1.1};
    }
  }

  .btn-accordion {
    position: relative;
    width: 100%;
    text-align: left;
    text-decoration: none;
    @include font-size(1.2rem);
    padding: $accordion-padding-y var(--accordion-padding-x, #{$accordion-padding-x});
    transition: all ease-out 150ms;

    &::before {
      content: '';
      @extend %base-icon;
      @include font-size(0.75rem);
      position: absolute;
      top: 0;
      left: calc(var(--accordion-padding-x, #{$accordion-padding-x}) / 3);
      bottom: 0;
      margin: auto;
      background-color: $coral;
      color: color-yiq($coral);
      border-radius: 100%;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 0.1em;
      transition: transform ease-in-out 350ms;
    }

    &:focus {
      outline: unset;
      box-shadow: unset;
    }

    &.chevron {
      &::before {
        content: $mi-chevron-down;
      }

      &.collapsed {
        &::before {
          transform: rotate(-180deg);
        }
      }
    }

    &.plus-minus {
      &::before {
        @include font-size(1rem);
        content: $mi-plus;
      }

      &.collapsed {
        &::before {
          content: $mi-minus;
        }
      }
    }

    &.element-inside {
      &::before {
        content: unset;
      }

      [class^='mi-'] {
        position: absolute;
        top: $accordion-padding-y;
        left: calc(var(--accordion-padding-x, #{$accordion-padding-x}) / 4);
        bottom: 0;
        height: max-content;
        line-height: 1.4;
      }

      @include media-breakpoint-down('md') {
        [class^='mi-'] {
          left: calc(var(--accordion-padding-x, #{$accordion-padding-x}) / 10);
        }
      }
    }

    .accordion-chevron {
      &::before {
        content: $mi-chevron-down;
        @extend %base-icon;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: max-content;
        transition: transform ease-in-out 350ms;
      }

      &.-left {
        &::before {
          left: calc(var(--accordion-padding-x, #{$accordion-padding-x}) / 2);
        }
      }

      &.-right {
        &::before {
          right: calc(var(--accordion-padding-x, #{$accordion-padding-x}) / 2);
        }
      }
    }

    &.collapsed {
      @include media-breakpoint-down('md') {
        border-bottom: solid 1px $grey;
      }

      .accordion-chevron {
        &::before {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .accordion-item {
    border-top: solid 1px $grey;

    &:last-child {
      border-bottom: solid 1px $grey;
    }

    &__content {
      $content-padding-y: 0.25rem;
      $content-padding-x: 2.5rem;
      padding: $content-padding-y $content-padding-x;
      background-color: $white;

      @include media-breakpoint-down('md') {
        $content-padding-x: 15px;
        $content-padding-y: 0.5rem;
        padding: #{$content-padding-y * 2.5} $content-padding-x $content-padding-y;
      }

      .-eq-padding-x {
        padding: {
          left: calc(var(--accordion-padding-x, #{$accordion-padding-x}) - #{$content-padding-x});
          right: calc(var(--accordion-padding-x, #{$accordion-padding-x}) - #{$content-padding-x});
        }
      }
    }

    .btn-accordion {
      &.collapsed {
        background-color: $white;
      }
    }
  }
}
