@import 'scss/for-module';

.wrapper {
  flex: 1 auto;
}

.form {
  &__wrapper {
    @include media-breakpoint-up('md') {
      border-left: solid 1px $grey;
    }

    @include media-breakpoint-down('md') {
      border-top: solid 1px $grey;
    }
  }
}

.input {
  &__wrapper {
    @include media-breakpoint-down('md') {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}
