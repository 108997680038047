// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #0000c1 !default;
$blueNavy: #00009a !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$coral: #ec6453 !default;
$violet: #604584 !default;
$orange: #fd7e14 !default;
$gold: #f49600 !default;
$yellow: #ffc107 !default;
$green: #00a758 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$turquoise: #28d7cb !default;
$scarletGum: #361558 !default;
$eggBlue: #06c7ba !default;
$xlsColor: #06874e !default;
$pdfColor: $blueNavy !default;
// $
// scss-docs-end color-variables

// scss-docs-start colors-map

$colors: () !default;
$colors: map-merge(
  (
    'blue': $blue,
    'blueNavy': $blueNavy,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'coral': $coral,
    'violet': $violet,
    'orange': $orange,
    'gold': $gold,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'turquoise': $turquoise,
    'scarletGum': $scarletGum,
    'eggBlue': $eggBlue,
    'xlsColor': $xlsColor,
    'pdfColor': $pdfColor,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $green !default;
@if $jh_theme {
  $primary: $blue !default;
}

$secondary: $violet !default;
$success: #00a758 !default;
$info: $cyan !default;
$warning: #f49600 !default;
$danger: #dc5a44 !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;
$grey: #ededed !default;
// scss-docs-end theme-color-variables

// Risk Colors
$konservatif: #06c7ba;
$moderat: #f49600;
$agresif: #361558;

$onboardingIcon: #424559;

// scss-docs-start theme-colors-map
$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'blueNavy': $blueNavy,
    'eggBlue': $eggBlue,
    'scarletGum': $scarletGum,
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'xlsColor': $xlsColor,
    'pdfColor': $pdfColor,
    'konservatif': $konservatif,
    'moderat': $moderat,
    'agresif': $agresif,
    'onboardingIcon': $onboardingIcon,
  ),
  $theme-colors
);

$darkNavy: #282b3e !default;
$lightNavy: #34384b !default;
$light3Navy: #5e6073 !default;
$light4Navy: #8e90a2 !default;
$brightGray: #34384b !default;
$ebonyClay: #282b3e !default;

$typography-colors: () !default;
$typography-colors: map-merge(
  (
    'darkNavy': $darkNavy,
    'light3Navy': $light3Navy,
    'light4Navy': $light4Navy,
    'white': $white,
  ),
  $typography-colors
);

$theme-colors: map-merge($typography-colors, $theme-colors);

$darkCoral: #d03a39;

$button-colors: () !default;
$button-colors: map-merge(
  (
    'coral': $coral,
    'darkCoral': $darkCoral,
    'white': $white,
  ),
  $button-colors
);

$theme-colors: map-merge($theme-colors, $button-colors);

$background-colors: () !default;
$background-colors: map-merge(
  (
    'greyLight': $gray-200,
    'white': $white,
    'darkNavy': $darkNavy,
    'lightNavy': $lightNavy,
    'brightGray': $brightGray,
    'ebonyClay': $ebonyClay,
  ),
  $background-colors
);

// scss-docs-end theme-colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 2.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// fusv-disable
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

@mixin useVarsColor {
  @each $name, $color in $theme-colors {
    --#{$name}: #{$color};
  }
}
