@import 'scss/for-module';

.card {
  background-color: transparent !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  overflow-x: hidden;

  &__checked {
    background-color: #caeed9 !important;
  }
}
