@import 'scss/for-module';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.tabs {
  position: relative;

  @include media-breakpoint-up('md') {
    %common-sprite {
      content: '';
      position: absolute;
      bottom: 0;
      height: 0px;
      width: 100%;
      border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
    }

    &::after {
      @extend %common-sprite;
      left: 0;
      transform: translateX(-100%);
    }

    &::before {
      @extend %common-sprite;
      right: 0;
      transform: translateX(100%);
    }
  }

  @include media-breakpoint-down('md') {
    padding: unset !important;
    margin: unset !important;
  }
}
