@import 'scss/for-module';

.navigation {
  $size: var(--logoIcon-size, 4rem);
  $childSize: var(--sidebar-child-size, 15rem);
  $background: $brightGray;
  $color: color-yiq($background);
  // background-color: $background;
  color: $color;
  width: 100%;
  left: 0;
  top: $size;
  bottom: 0;
  justify-content: flex-start;
  z-index: $zindex-modal;
  background-color: $white;
  max-height: calc(100vh - var(--logoIcon-size, 4rem));
  max-width: 100vw;

  &__nav {
    list-style: none;
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: column;
    color: $brightGray;
    flex-wrap: unset !important;
    overflow-y: scroll;

    &__link {
      --bg-opacity: 0;
      display: flex !important;
      padding: 0 !important;
      max-width: 100%;
      color: inherit;
      text-decoration: none;
      flex: 1 auto;
      flex-direction: row;
      align-items: center;
      height: $size;
      max-height: $size;
      background-color: rgba($ebonyClay, var(--bg-opacity));
      transition: all ease-in 120ms;

      i[class^='mi-'] {
        width: $size;
        font-size: 1.5rem;
        // opacity: 0.4;
        transition: opacity ease-in 120ms;
        margin-bottom: 0.15em;
        display: flex;
        justify-content: center;
      }

      &.has-active,
      &:hover,
      &:focus {
        --bg-opacity: 0.1;
        color: inherit !important;
        border-left: solid 4px $primary;

        i[class^='mi-'] {
          opacity: 1;
        }
      }
    }

    &__item {
      &__label {
        font-size: 0.825rem;
        font-weight: 300;
      }
    }

    li {
      border-bottom: solid 1px $gray-300;
      display: flex;
    }
  }

  &__childNav {
    font-size: 0.9rem;
    position: fixed;
    width: 100%;
    left: 0;
    top: $size;
    bottom: 0;
    background-color: $white;
    z-index: $zindex-modal + 2;
    border-right: solid 1px $grey;
    height: calc(100vh - var(--logoIcon-size, 4rem));
    overflow-y: scroll;

    li {
      width: 100%;
      border-bottom: solid 1px $gray-300;
      display: flex;
    }

    &__brand {
      box-shadow: inset 0px -1px 0px $grey;
    }

    &__back {
      display: flex;
      padding: {
        left: 0 !important;
      }

      i {
        width: $size;
        text-align: center;
        color: $coral;
      }
    }

    &__link {
      position: relative;
      display: flex !important;
      align-items: center;
      justify-content: flex-start;
      color: $black;
      width: 100%;
      height: $size;
      @include transition($btn-transition);
      padding: {
        left: calc(#{$size / 3});
        right: calc(#{$size / 3});
      }

      &.has-active,
      &:hover,
      &:focus {
        background-color: $body-bg;
      }

      &.has-active {
        &::before {
          content: '';
          position: absolute;
          width: 0.25rem;
          height: 100%;
          top: 0;
          left: 0;
          background-color: $primary;
        }
      }
    }
  }

  &__footer {
    font-size: 0.825rem;
    z-index: $zindex-modal + 1;
    background-color: $body-bg;
    position: absolute;
    bottom: 0;

    &__lang {
      height: $size;
      align-items: center;
      // width: 100%;

      &__selector {
        line-height: 0.45;
        border-left: 1px solid $grey !important;
        color: $light4Navy !important;

        &.has-active {
          color: $darkNavy !important;
        }
      }
    }

    &__signout {
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 11px 12px;
      border-color: $coral;
      background-color: $white;
    }
  }
}
