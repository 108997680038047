@import 'scss/for-module';

.wrapper {
  $sidebarWidth: 5.15rem;
  $sidebarChildWidth: 17rem;
  $gap: $sidebarWidth / 4.256;
  --sidebar-size: #{$sidebarWidth};
  --sidebar-child-size: #{$sidebarChildWidth};
  --sidebar-gap: #{$gap};
  --sidebar-initial-gap: #{$gap};
  --header-left: #{$sidebarWidth};

  &.showSidebarChild {
    --sidebar-gap: calc((#{$sidebarWidth} / 4.256) + var(--sidebar-child-size));
    --header-left: calc((#{$sidebarWidth}) + var(--sidebar-child-size));
  }

  .header {
    position: fixed;
    margin-top: 0;

    @include media-breakpoint-up('lg') {
      left: var(--header-left);
    }

    @include media-breakpoint-down('md') {
      left: 0;
    }

    right: 0;
    top: 0;

    & > div {
      transition: all ease-out 250ms;
      @include media-breakpoint-up('lg') {
        height: $sidebarWidth;
        padding: {
          left: $gap !important;
          right: $gap !important;
        }
      }
    }
  }

  .footer {
    position: relative;
    z-index: $zindex-modal-backdrop;

    @include media-breakpoint-up('md') {
      margin-left: calc(-1 * #{$gap});
      margin-right: calc(-1 * #{$gap});
      padding-left: $gap;
      padding-right: $gap;
    }
  }

  .content {
    --transition: opacity 300ms, transform 300ms;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    padding-top: $sidebarWidth;

    @include media-breakpoint-up('lg') {
      padding: {
        left: calc(#{$sidebarWidth} + var(--sidebar-gap, #{$gap}));
        right: $gap;
      }
    }
  }
}
