@import 'variables';

%base-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: $icon-font-size;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='mi-'],
[class*=' mi-'] {
  --rotate: 0deg;
  @extend %base-icon;
  transform: rotate(var(--rotate));

  &.sm {
    font-size: $icon-font-size * 0.75 !important;
    &.-for-btn {
      line-height: 2.45 !important;
    }
  }

  &.md {
    font-size: $icon-font-size * 1.2 !important;
  }

  &.lg {
    font-size: $icon-font-size * 1.35 !important;
  }

  &.xl {
    font-size: $icon-font-size * 2 !important;
  }

  &.xxl {
    font-size: $icon-font-size * 2.75 !important;
  }

  &.xxl2 {
    font-size: $icon-font-size * 3.75 !important;
  }

  &.ultra {
    font-size: $icon-font-size * 5 !important;
  }

  &.r-90 {
    --rotate: 90deg;
  }

  &.\-r-125 {
    --rotate: -125deg;
  }
}

.mi-id-card {
  &:before {
    content: $mi-id-card;
  }
}
.mi-reload {
  &:before {
    content: $mi-reload;
  }
}
.mi-form-completed {
  &:before {
    content: $mi-form-completed;
  }
}
.mi-cash {
  &:before {
    content: $mi-cash;
  }
}
.mi-spouse {
  &:before {
    content: $mi-spouse;
  }
}
.mi-sort {
  &:before {
    content: $mi-sort;
  }
}
.mi-arrow-right {
  &:before {
    content: $mi-arrow-right;
  }
}
.mi-arrow-left {
  &:before {
    content: $mi-arrow-left;
  }
}
.mi-arrow-up {
  &:before {
    content: $mi-arrow-up;
  }
}
.mi-arrow-down {
  &:before {
    content: $mi-arrow-down;
  }
}
.mi-chevron-outlined-left {
  &:before {
    content: $mi-chevron-outlined-left;
  }
}
.mi-hamburger {
  &:before {
    content: $mi-hamburger;
  }
}
.mi-chevron-outlined-bottom {
  &:before {
    content: $mi-chevron-outlined-bottom;
  }
}
.mi-chevron-outlined-top {
  &:before {
    content: $mi-chevron-outlined-top;
  }
}
.mi-chevron-outlined-right {
  &:before {
    content: $mi-chevron-outlined-right;
  }
}
.mi-briefcase {
  &:before {
    content: $mi-briefcase;
  }
}
.mi-save-file {
  &:before {
    content: $mi-save-file;
  }
}
.mi-old-people {
  &:before {
    content: $mi-old-people;
  }
}
.mi-long-form {
  &:before {
    content: $mi-long-form;
  }
}
.mi-chevron-down {
  &:before {
    content: $mi-chevron-down;
  }
}
.mi-double-chevron-right {
  &:before {
    content: $mi-double-chevron-right;
  }
}
.mi-double-chevron-left {
  &:before {
    content: $mi-double-chevron-left;
  }
}
.mi-double-chevron-up {
  &:before {
    content: $mi-double-chevron-up;
  }
}
.mi-double-chevron-down {
  &:before {
    content: $mi-double-chevron-down;
  }
}
.mi-chevron-right {
  &:before {
    content: $mi-chevron-right;
  }
}
.mi-chevron-left {
  &:before {
    content: $mi-chevron-left;
  }
}
.mi-chevron-up {
  &:before {
    content: $mi-chevron-up;
  }
}
.mi-instagram {
  &:before {
    content: $mi-instagram;
  }
}
.mi-linkedin {
  &:before {
    content: $mi-linkedin;
  }
}
.mi-youtube {
  &:before {
    content: $mi-youtube;
  }
}
.mi-twitter {
  &:before {
    content: $mi-twitter;
  }
}
.mi-facebook {
  &:before {
    content: $mi-facebook;
  }
}
.mi-badge-ok {
  &:before {
    content: $mi-badge-ok;
  }
}
.mi-resources {
  &:before {
    content: $mi-resources;
  }
}
.mi-minus {
  &:before {
    content: $mi-minus;
  }
}
.mi-received {
  &:before {
    content: $mi-received;
  }
}
.mi-notification {
  &:before {
    content: $mi-notification;
  }
}
.mi-filter {
  &:before {
    content: $mi-filter;
  }
}
.mi-pencil {
  &:before {
    content: $mi-pencil;
  }
}
.mi-plus {
  &:before {
    content: $mi-plus;
  }
}
.mi-eclaims {
  &:before {
    content: $mi-eclaims;
  }
}
.mi-calendar {
  &:before {
    content: $mi-calendar;
  }
}
.mi-alert {
  &:before {
    content: $mi-alert;
  }
}
.mi-search {
  &:before {
    content: $mi-search;
  }
}
.mi-download {
  &:before {
    content: $mi-download;
  }
}
.mi-user {
  &:before {
    content: $mi-user;
  }
}
.mi-funds {
  &:before {
    content: $mi-funds;
  }
}
.mi-form {
  &:before {
    content: $mi-form;
  }
}
.mi-about {
  &:before {
    content: $mi-about;
  }
}
.mi-badge-dollar {
  &:before {
    content: $mi-badge-dollar;
  }
}
.mi-trash {
  &:before {
    content: $mi-trash;
  }
}
.mi-check {
  &:before {
    content: $mi-check;
  }
}
.mi-cross {
  &:before {
    content: $mi-cross;
  }
}
.mi-envelope {
  &:before {
    content: $mi-envelope;
  }
}
.mi-eye-close {
  &:before {
    content: $mi-eye-close;
  }
}
.mi-eye-open {
  &:before {
    content: $mi-eye-open;
  }
}
.mi-info {
  &:before {
    content: $mi-info;
  }
}
.mi-phone {
  &:before {
    content: $mi-phone;
  }
}
.mi-union {
  &:before {
    content: $mi-union;
  }
}
