@import 'scss/for-module';

.wrapper {
  flex: 1 auto;
}

.tabs {
  width: auto !important;
  border: $grey solid 1px;
  background-color: $white;

  a[role='tab'] {
    width: 50%;
    margin: 0;
    text-align: center;
    padding: 1rem 0;

    &:not([aria-selected='true']) {
      border-left: $grey solid 1px;
      border-right: $grey solid 1px;
    }
  }
}
