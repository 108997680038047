.stepper {
  &__steps {
    @extend .list-unstyled;
    counter-reset: stepper-counter;
    display: flex;
    flex-wrap: wrap;

    &__wrapper {
      position: relative;

      // @include media-breakpoint-down('sm') {
      //   overflow-x: auto;
      // }

      // &__inner {
      //   @include media-breakpoint-down('sm') {
      //     width: max-content;
      //   }
      // }
    }
  }

  &__step {
    display: flex;
    justify-content: center;
    flex: 1 auto;
    counter-increment: stepper-counter;

    &__anchor {
      --anchor-content: counter(stepper-counter);
      --icon-bg: #{$darkNavy};
      --icon-color: #{color-yiq($darkNavy)};
      @include transition($btn-transition);
      text-decoration: none;
      display: flex;

      &::before {
        $size: 1.5rem;
        content: var(--anchor-content);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
        background-color: var(--icon-bg);
        color: var(--icon-color);
        width: $size;
        height: $size;
        border-radius: $size;
        font-size: 0.75rem;
        font-weight: 900;
        @include transition($btn-transition);
      }

      &.-passed {
        --anchor-content: '#{$mi-check}';
        --icon-bg: #{$primary};
        --icon-color: #{color-yiq($primary)};

        &::before {
          @extend %base-icon;
        }
      }

      &:disabled,
      &.-disabled {
        color: $grey;
        --icon-bg: #{$grey};
        --icon-color: #{$white};
      }
    }

    &__content {
      &.transition-slide-exit {
        position: absolute;
      }

      &.transition-slide-enter-done {
        position: relative;
      }
    }
  }
}
