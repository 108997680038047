@import 'scss/for-module';

.wrapper {
  $ecard-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1 auto;

  @include media-breakpoint-down(sm) {
    button {
      width: 100%;
    }
  }

  & > * {
    flex: 1;
    margin: {
      top: 1rem;
      bottom: 3rem;
    }
  }

  .ecardImage {
    filter: drop-shadow(#{$ecard-shadow});
  }

  .ecardPlaceholder {
    min-height: 32.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    box-shadow: $ecard-shadow;
    border: solid 1px #dedede;

    &__icon {
      font-size: 4.5rem !important;
      margin-right: 0.5rem;
    }

    p {
      margin: 0;
      font-weight: 200;
    }
  }
}
