@import 'scss/for-module';

.header {
  z-index: $zindex-modal-backdrop - 1;
  transition: background-color cubic-bezier(0.215, 0.61, 0.355, 1) 120ms;

  margin: {
    top: 2rem;
  }

  &__brand {
    position: relative;

    &__icon {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      transform: translateX(calc(-100% + -0.85rem));
      display: flex;
      align-items: center;
      color: $primary;
    }
  }

  &__langDropdown {
    width: 3.5rem !important;
  }
}
