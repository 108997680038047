%base-list {
  @extend .list-unstyled;
  padding-left: 1rem;

  & > li {
    position: relative;
    margin: {
      top: 0.4rem;
      bottom: 0.4rem;
    }

    &::before {
      @extend %base-icon;
      @include font-size(0.8rem);
      transform: translateX(calc(-100% - 0.25rem));
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: max-content;
      margin: auto;
    }
  }

  &.lg-icon {
    padding-left: 1.4rem;

    & > li {
      &::before {
        @include font-size(1.2rem);
      }
    }
  }
}

.union-list {
  @extend %base-list;

  & > li {
    &::before {
      content: $mi-union;
    }
  }
}

.cross-list {
  @extend %base-list;

  & > li {
    &::before {
      content: $mi-cross;
    }

    &.checked {
      &::before {
        content: $mi-check;
      }
    }
  }
}
