@import 'scss/for-module';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.text {
  &__register {
    display: block;
    margin-bottom: 0px;

    @include media-breakpoint-down('sm') {
      margin-bottom: 2rem;
    }
  }
}

.form {
  width: 100%;
  max-width: 32rem;
  margin-top: 2rem;
  margin-bottom: auto;

  @include media-breakpoint-down('sm') {
    max-width: 28rem;
    button {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}
