$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: '../../fonts/icons' !default;
$icon-font-size: 1rem;

$mi-id-card: '\e93a';
$mi-reload: '\e939';
$mi-form-completed: '\e938';
$mi-cash: '\e936';
$mi-spouse: '\e937';
$mi-sort: '\e90c';
$mi-arrow-right: '\e932';
$mi-arrow-left: '\e933';
$mi-arrow-up: '\e934';
$mi-arrow-down: '\e935';
$mi-chevron-outlined-left: '\e92d';
$mi-hamburger: '\e92e';
$mi-chevron-outlined-bottom: '\e92f';
$mi-chevron-outlined-top: '\e930';
$mi-chevron-outlined-right: '\e931';
$mi-briefcase: '\e929';
$mi-save-file: '\e92a';
$mi-old-people: '\e92b';
$mi-long-form: '\e92c';
$mi-chevron-down: '\e918';
$mi-double-chevron-right: '\e922';
$mi-double-chevron-left: '\e923';
$mi-double-chevron-up: '\e924';
$mi-double-chevron-down: '\e925';
$mi-chevron-right: '\e926';
$mi-chevron-left: '\e927';
$mi-chevron-up: '\e928';
$mi-instagram: '\e90a';
$mi-linkedin: '\e913';
$mi-youtube: '\e914';
$mi-twitter: '\e915';
$mi-facebook: '\e916';
$mi-badge-ok: '\e921';
$mi-resources: '\e900';
$mi-minus: '\e901';
$mi-received: '\e902';
$mi-notification: '\e903';
$mi-filter: '\e904';
$mi-pencil: '\e905';
$mi-plus: '\e906';
$mi-eclaims: '\e907';
$mi-calendar: '\e908';
$mi-alert: '\e909';
$mi-search: '\e90b';
$mi-download: '\e90d';
$mi-user: '\e90e';
$mi-funds: '\e90f';
$mi-form: '\e910';
$mi-about: '\e911';
$mi-badge-dollar: '\e912';
$mi-trash: '\e917';
$mi-check: '\e919';
$mi-cross: '\e91a';
$mi-envelope: '\e91b';
$mi-eye-close: '\e91c';
$mi-eye-open: '\e91d';
$mi-info: '\e91e';
$mi-phone: '\e91f';
$mi-union: '\e920';
