@import 'scss/for-module';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @include media-breakpoint-down('sm') {
    margin-bottom: 2rem;

    &:last-child {
      width: 100%;
    }
  }
}

.subheading {
  max-width: 32rem;
  font-weight: 300;
}

.cardLink {
  width: 100%;
  height: 10rem;
  background-color: $white;
  text-align: center;
  text-decoration: none;

  [class^='card-body'] {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
}
