@import 'scss/for-module';

.wrapper {
  //
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 auto;

  &__inner {
    flex: 1 auto;
  }

  @include media-breakpoint-up('lg') {
    margin-left: calc((100vw - #{map-get($container-max-widths, 'lg')}) / 2);
  }

  @include media-breakpoint-up('xl') {
    margin-left: calc((100vw - #{map-get($container-max-widths, 'xl')}) / 2);
  }

  // @include media-breakpoint-up('md') {
  //   margin-left: calc((100vw - #{map-get($container-max-widths, 'md')}) / 2);
  // }
}

.side {
  &__bg {
    background-size: cover;
    background-position: center;
    height: 100vh;

    @include media-breakpoint-down('sm') {
      height: 30vh;
      background-position-y: 90%;
    }
  }
}
