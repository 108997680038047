@import 'scss/for-module';

.tabs {
  width: auto !important;
  border: $grey solid 1px;
  background-color: $white;

  a[role='tab'] {
    width: 50%;
    margin: 0;
    text-align: center;
    padding: 1rem 0;

    &:not([aria-selected='true']) {
      border-left: unset;
      border-right: unset;

      @include media-breakpoint-up('md') {
        border-left: $grey solid 1px;
        border-right: $grey solid 1px;
      }
    }

    &[aria-selected='true'] {
      @include media-breakpoint-down('md') {
        background-color: $white;
      }
    }
  }
}
