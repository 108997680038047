.date-picker {
  position: relative;
  display: inline-block;
  z-index: 100;

  &__form-group {
    margin-bottom: 0;

    &.-rtl {
      direction: rtl;
    }
  }

  &__calendar-arrow {
    position: absolute;
    width: 0;
    height: 0;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    margin: 0 auto;
    border-style: solid;
    z-index: 10;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $white transparent;
  }

  &__calendar-container {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);

    &.-top {
      top: auto;
      bottom: calc(100% + 20px);

      & + .date-picker__calendar-arrow {
        top: auto;
        bottom: calc(100% + 10px);
        transform: translateY(-2.5rem) rotate(180deg);
        animation: fadeArrowFlipped 0.3s forwards;
      }

      .calendar {
        transform: translateY(-2.5em);
      }
    }
  }

  .calendar,
  &__calendar-arrow {
    transform: translateY(2.5em);
    opacity: 0;
    animation: fadeCalendar 0.3s forwards;
  }
}

.calendar {
  --cl-color-black: #{$darkNavy};
  --cl-color-disabled: #{darken($grey, 20%)};
  --cl-color-error: #{$darkNavy};
  $size: 18rem;
  font-size: 10px;
  background: $white;
  box-shadow: 0 1em 4em rgba(0, 0, 0, 0.07);
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: column;
  width: $size;
  z-index: 10;
  max-width: 90vw;

  &.-rtl * {
    direction: rtl;
  }

  &.-no-focus-outline *:focus {
    outline: none !important;
  }

  & > :not(.calendar__footer) button {
    font-family: inherit;
    background: transparent;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    outline: none;
  }

  &__header {
    display: flex;
    color: var(--cl-color-black);
    padding: 1.5em 1.9em;
    align-items: center;
    overflow: hidden;
    border-bottom: solid 1px $grey;
    height: 100%;
    max-height: 5em;
  }

  &__month-arrow-wrapper {
    line-height: 0;
    font-size: 1em;
    padding: 3px;
    position: relative;
    border: none;
    z-index: 1;
    opacity: 1;
    transition: 0.2s;

    &:focus {
      outline: 1px dashed rgba(0, 0, 0, 0.4);
      outline-offset: 2px;
    }

    &:disabled,
    &.-hidden {
      // opacity: 0;
      // pointer-events: none;
      cursor: not-allowed !important;
    }

    &.-left {
      margin-right: -0.5em;
      span {
        transform: rotate(-90deg);
      }
    }

    &.-right {
      margin-right: 0.5em;
      span {
        transform: rotate(90deg);
      }
    }

    &.-rtl &.-left {
      span {
        transform: rotate(90deg);
      }
    }

    &.-rtl &.-right {
      span {
        transform: rotate(-90deg);
      }
    }
  }

  &__month-arrow {
    @extend %base-icon;
    @extend .mi-chevron-down;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: var(--animation-duration) transform;
    pointer-events: none;
    width: 1em;
    height: 1em;
    font-size: 1.2em;
  }

  &__month-year-container {
    flex: 1;
    position: relative;
  }

  &__month-year {
    font-size: 1.6em;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    will-change: transform, opacity;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: var(--animation-duration);
    line-height: 1;
  }

  &__month-year.-hidden-next {
    opacity: 0;
    transform: translateX(50%);
  }

  &.-rtl &__month-year.-hidden-next {
    transform: translateX(-150%);
  }

  &__month-year.-hidden-previous {
    opacity: 0;
    transform: translateX(-150%);
  }

  &.-rtl &__month-year.-hidden-previous {
    transform: translateX(50%);
  }

  &__month-year.-shown {
    opacity: 1;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__month-year.-shown-animated {
    animation: var(--animation-duration) fadeTextToCenter forwards;
  }

  &__month-year > * {
    padding: 0.2em 0em;
    border: 1px solid transparent;
    transition: var(--animation-duration);
    font-size: 1.05em;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(0) scale(0.95);
    will-change: transform;
    border-radius: 5px;

    &::after {
      @extend %base-icon;
      content: $mi-chevron-down;
      font-size: 0.8em;
      margin-left: 0.3em;
    }
  }

  &__month-year:not(.-shown) > *,
  &__month-year > *.-hidden {
    cursor: default;
    pointer-events: none;
  }

  &__month-text {
    margin-left: -0.3em;

    & + button {
      margin-left: 1em;
    }
  }

  &__month-year &__year-text.-hidden {
    transform: translateX(50%);
    opacity: 0;
  }

  &.-rtl &__month-year &__year-text.-hidden {
    transform: translateX(-50%);
  }

  &__month-year &__month-text.-hidden {
    transform: translateX(-50%);
    opacity: 0;
  }

  &.-rtl &__month-year &__month-text.-hidden {
    transform: translateX(50%);
  }

  &__month-year:not(.-shown) > * {
    pointer-events: none;
  }

  &__month-selector-animation-wrapper,
  &__year-selector-animation-wrapper {
    position: absolute;
    width: 100%;
    height: calc(100% - 5em);
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__month-selector-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__month-selector {
    padding: 0 0.25em;
    align-content: center;
    // padding-bottom: 2em;
  }

  &__month-selector,
  &__year-selector {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    background-color: $white;
    transform: translateY(-150%);
    will-change: transform;
    transition: 0.6s;
    height: 100%;
  }

  &__year-selector-wrapper {
    width: 100%;
    height: 100%;
  }

  &__year-selector-wrapper::after,
  &__year-selector-wrapper::before {
    content: '';
    width: 100%;
    height: 5em;
    position: absolute;
    left: 0;
    opacity: 0;
    transition: 0.4s;
    transition-delay: 0.2s;
  }

  &__year-selector-wrapper::after {
    background-image: linear-gradient(to bottom, $white, $white 10%, rgba(245, 245, 245, 0));
    top: -0.1em;
  }

  &__year-selector-wrapper::before {
    background-image: linear-gradient(to top, $white, $white 10%, rgba(245, 245, 245, 0));
    bottom: 0;
  }

  &__year-selector-wrapper.-faded::after,
  &__year-selector-wrapper.-faded::before {
    opacity: 1;
    z-index: 3;
    pointer-events: none;
  }

  &__year-selector {
    align-content: flex-start;
    scrollbar-width: 0;
    overflow: scroll;
    position: relative;
    width: 100%;
    padding: 3.8em 0.45em;
    -ms-overflow-style: none;
  }

  &__year-selector::-webkit-scrollbar {
    display: none;
  }

  &__year-selector-item {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  &__year-selector-item:not(:nth-child(-n + 4)) {
    margin-top: 1em;
  }

  &__year-selector-text {
    border: solid transparent 2px;
    padding: 0.55em 0.4em;
    border-radius: 5em;
    font-size: 1.4em;
    min-width: 85%;
  }

  &__month-selector.-open,
  &__year-selector.-open {
    transform: translateY(0);
  }

  &__year-selector-text:focus,
  &__month-selector-item-text:focus {
    outline: 1px dashed rgba(0, 0, 0, 0.4);
    outline-offset: 2px;
  }

  &__month-selector-item {
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
  }

  &__month-selector-item:not(:nth-child(-n + 3)) {
    margin-top: 1em;
  }

  &__month-selector-item-text {
    border: solid transparent 2px;
    padding: 0.75em 0.4em;
    border-radius: 5em;
    font-size: 1.3em;
    min-width: 70%;
    transition: 0.3s;
  }

  &__month-selector-item:not(.-active) &__month-selector-item-text:not(:disabled):hover,
  &__year-selector-item:not(.-active) &__year-selector-text:not(:disabled):hover {
    background: transparent;
    border-color: var(--cl-color-primary, #{$primary});
  }

  &__month-selector-item-text:disabled,
  &__year-selector-text:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &__month-selector-item.-active &__month-selector-item-text,
  &__year-selector-item.-active &__year-selector-text {
    background-color: var(--cl-color-primary, #{$primary});
    color: $white;
  }

  &__week-days {
    display: flex;
    justify-content: space-between;
    color: var(--cl-color-disabled);
    font-size: 1.2em;
    padding: 0 0.5em;
    position: relative;
    margin-top: 1.35em;
    margin-bottom: 0.85em;
  }

  &__week-day {
    display: block;
    width: calc(100% / 7);
    max-width: $size / 7;
    text-align: center;
    text-decoration: none;
  }

  &__section-wrapper {
    position: relative;
    min-height: $size;
    overflow: hidden;
  }

  &__section {
    display: flex;
    flex-direction: column;
    padding: 0 0.6em;
    position: absolute;
    color: var(--cl-color-black);
    top: 0;
    padding-top: 0.3em;
    left: 0;
    width: 100%;
    will-change: transform, opacity;
    transform: translateZ(0);
    backface-visibility: hidden;
    transition: var(--animation-duration);
  }

  &__section.-hidden-previous {
    opacity: 0.5;
    transform: translateX(-100%);
  }

  &.-rtl &__section.-hidden-previous {
    transform: translateX(100%);
  }

  &__section.-hidden-next {
    opacity: 0.5;
    transform: translateX(100%);
  }

  &.-rtl &__section.-hidden-next {
    transform: translateX(-100%);
  }

  &__section.-shown {
    opacity: 1;
    transform: translateX(0);
  }

  &__section.-shown-animated {
    animation: var(--animation-duration) FadeContentToCenter forwards;
  }

  &__week-row {
    display: flex;
    width: 100%;
  }

  &__day {
    width: calc(100% / 7);
    max-width: $size / 7;
    height: $size / 7;
    text-align: center;
    padding: calc(0.25em - 1px) 0;
    font-size: 1.4em;
    border-radius: 50%;
    transition: 0.2s;
    border: 2px solid transparent;
    margin-bottom: 0.3em;
    color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__day:focus {
    outline: 1px dashed rgba(0, 0, 0, 0.4);
    outline-offset: 2px;
  }

  &__day:not(.-blank):not(.-selected-start):not(.-selected-end):not(.-selected-between):not(.-selected):hover {
    background: transparent;
    border-radius: 50%;
    color: var(--cl-color-black);
    border-color: var(--cl-color-primary, #{$primary});
  }

  &__day.-selected,
  &__day.-selected-start,
  &__day.-selected-end {
    background: var(--cl-color-primary, #{$primary});
    color: $white;
  }

  &__day.-selected-between {
    position: relative;
    color: var(--cl-color-primary, #{$primary});
    background-color: transparent;
    border-radius: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -1em;
      bottom: 0;
      left: -1em;
      background: var(--cl-color-primary-light, #cff4d5);
      z-index: -1;
      height: 90%;
      margin: auto;
      pointer-events: none;
    }

    &:last-child {
      &::before {
        right: 0;
      }
    }

    &:first-child {
      &::before {
        left: 0;
      }
    }
  }

  &__day.-weekend:not(.-selected):not(.-blank):not(.-selected-start):not(.-selected-end):not(.-selected-between) {
    color: var(--cl-color-error);
  }

  &__day.-weekend.-today:not(.-selected-start):not(.-selected-end):not(.-selected-between)::after {
    background: var(--cl-color-error);
  }

  &__day.-disabled {
    color: var(--cl-color-disabled) !important;
    background: transparent !important;
    cursor: default !important;
  }

  &__day.-selected {
    border-radius: 50%;
  }

  &__day.-today:not(.-selected-start):not(.-selected-end):not(.-selected-between) {
    font-weight: 900;
    color: var(--cl-color-black);
    position: relative;
    border-color: var(--cl-color-disabled);
  }

  &__day.-today:hover:not(.-selected-start):not(.-selected-end):not(.-selected-between)::after {
    opacity: 0;
  }

  &__day.-blank {
    color: var(--cl-color-disabled, #{$grey});
    cursor: default;
    pointer-events: none;
  }

  &__footer {
    position: relative;
    z-index: 1;
  }

  &.-month-only {
    .calendar {
      &__month-selector {
        margin-bottom: 0;

        &-animation-wrapper {
          min-height: $size * 0.85;
          position: relative;
          height: 100%;

          .-selected-between,
          .-active.-mark {
            position: relative;
            background-color: transparent;
            border-radius: 0;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              right: -4em;
              bottom: 0;
              left: -4em;
              background: var(--cl-color-primary-light, #cff4d5);
              z-index: -1;
              height: 90%;
              margin: auto;
              pointer-events: none;
            }

            &:last-child {
              &::before {
                right: 0;
              }
            }

            &:first-child {
              &::before {
                left: 0;
              }
            }
          }

          .-selected-between {
            .calendar__month-selector-item-text {
              color: var(--cl-color-primary, #{$primary});

              &:hover,
              &:focus {
                border-color: transparent !important;
              }
            }
          }

          .-active.-mark {
            &.-start {
              &::before {
                left: 50%;
              }
            }
            &.-end {
              &::before {
                right: 50%;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeCalendar {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeArrowFlipped {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translateY(0) rotate(180deg);
  }
}

@keyframes fadeTextToCenter {
  to {
    opacity: 1;
    transform: translateX(-50%);
  }
}

@keyframes FadeContentToCenter {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
