@import 'scss/for-module';

.tabs {
  width: auto !important;
  border: $grey solid 1px;
  background-color: $white;

  a[role='tab'] {
    flex: 1 1;
    margin: 0;
    text-align: center;
    padding: 1rem 0;

    &:not([aria-selected='true']) {
      border-left: $grey solid 1px;
      border-right: $grey solid 1px;
    }
  }
}

.ecard {
  $ecard-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $body-bg;
    padding: {
      top: $card-spacer-x * 1.5 !important;
      bottom: $card-spacer-x * 1.5 !important;
    }
  }

  &__button {
    position: absolute;
    top: $card-spacer-x * 0.85;
    right: $card-spacer-x * 0.85;
    z-index: 9;
  }
}
