.avatar {
  &__wrapper {
    position: relative;
    width: max-content !important;
    flex-basis: unset !important;
    flex-grow: unset !important;
  }

  &__button {
    position: absolute;
    bottom: 0.5em;
    right: 1em;
  }
}
