@import 'scss/for-module';

.header {
  z-index: $zindex-modal + 1;
  transition: background-color cubic-bezier(0.215, 0.61, 0.355, 1) 120ms;
  $background: $brightGray;
  $logoIconWidth: 4rem;
  --logoIcon-size: #{$logoIconWidth};
  // background-color: $background;
  height: $logoIconWidth;
  max-width: 100vw;

  &__icon {
    display: flex;

    align-items: center;
    justify-content: center;
    float: left;

    // background-color: $primary;
    height: $logoIconWidth;
    width: $logoIconWidth;
  }

  &__brand {
    height: $logoIconWidth;

    display: flex;
    float: left;
    align-items: center;
    justify-content: center;
    padding-left: 2rem;

    // width: calc(100% - ((var(--logoIcon-size))));
  }

  &__notification {
    width: $logoIconWidth;
    height: $logoIconWidth;
    display: inline-flex;
    float: right;
    align-items: center;
    justify-content: center;
    color: white;

    &:focus,
    &:hover,
    &:active {
      box-shadow: unset !important;
    }
  }

  &__toggle {
    width: $logoIconWidth;
    height: $logoIconWidth;
    display: inline-flex;
    float: right;
    align-items: center;
    justify-content: center;
    color: white;

    &:focus,
    &:hover,
    &:active {
      box-shadow: unset !important;
    }
  }

  &__langDropdown {
    width: 3.5rem !important;
  }

  button {
    border-color: transparent transparent transparent black;
    border-width: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.light {
  background-color: $white;

  &__icon {
    background-color: transparent;
  }
  button {
    background-color: transparent;
    border-color: transparent;
  }
}

.dark {
  background-color: $brightGray;

  &__icon {
    background-color: $primary;
  }
  button {
    background-color: $brightGray;
  }
}
