:root {
  font-size: 15.5px;
}

.font-serif {
  font-family: 'Manulife JH Serif', sans-serif !important;
  font-style: italic;
}

.transition-fade {
  &-enter {
    opacity: 0;

    &-active {
      opacity: 1;
      transition: opacity 200ms;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0;
      transition: opacity 200ms;
    }
  }
}

.transition-slide {
  &-enter {
    opacity: 0;
    transform: translateX(-20%);

    &-active {
      opacity: 1;
      transform: translateX(0%);
      transition: var(--transition, opacity 300ms, transform 500ms);
    }
  }

  &-exit {
    opacity: 1;
    transform: translateX(0%);

    &-active {
      opacity: 0;
      transform: translateX(20%);
      transition: var(--transition, opacity 300ms, transform 500ms);
    }
  }
}

.text-sm {
  font-size: 0.75rem;
}

.text-md {
  font-size: 1rem;
}

.text-xl {
  font-size: 2.25rem;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.vertical-align-top {
  vertical-align: top !important;
}

.vertical-align-text-bottom {
  vertical-align: text-bottom !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.opacity-90 {
  opacity: 0.9;
}

.border-t-1 {
  border-top-width: 1px !important;
}

.capitalize {
  text-transform: capitalize;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.normalize-container-fluid {
  margin: {
    left: -15px;
    right: -15px;
  }

  .list-group-item {
    @extend .container-fluid;
  }

  .accordion {
    &.row {
      margin: {
        left: 0;
        right: 0;
      }

      .row.no-gutters,
      .accordion-item {
        padding: {
          left: 0;
          right: 0;
        }
      }
    }
  }
}
