@import 'scss/for-module';

.footer {
  &__nav {
    border: {
      top: solid 2px $grey;
    }
    padding: {
      top: 1.25rem;
      bottom: 1.75rem;
    }

    &__links {
      @include media-breakpoint-down('sm') {
        padding-bottom: 0.25rem;
      }
    }

    &__link {
      padding: {
        left: 0px !important;
        right: 0px !important;
      }
    }

    &__item {
      padding: 0 1rem;

      @include media-breakpoint-down('md') {
        padding: 0 0.3rem;
        font-size: 0.965rem;
      }

      &:first-child {
        margin-left: -1rem;

        @include media-breakpoint-down('md') {
          margin-left: 0rem;
        }
      }

      &__icon {
        padding-left: 0.75rem;
        padding-right: 0.75rem;

        &:first-child {
          margin-left: -0.75rem;
        }

        & > a {
          padding: 0;

          [class^='mi-'] {
            font-size: 1.75rem;
          }
        }
      }
    }

    @include media-breakpoint-down('md') {
      border-top-width: 0px;
    }
  }

  &__brand {
    position: relative;
    display: flex;

    &__icon {
      margin-right: 1rem;
      color: $primary;
    }

    @include media-breakpoint-down('md') {
      margin-bottom: 1.25rem;
    }
  }

  &__copy {
    max-width: 100%;

    p {
      font-size: 0.85rem;
    }

    @include media-breakpoint-up('md') {
      max-width: 85%;
    }
  }

  &--dark {
    $bg: $darkNavy;
    $color: color-yiq($bg);
    background-color: $bg;
    color: $color;

    a {
      color: $color;

      &:hover,
      &:focus {
        color: darken($color, 10%);
      }
    }
  }

  &__bottom {
    padding: {
      top: 2.257rem;
      bottom: 2.257rem;
    }

    @include media-breakpoint-down('md') {
      border-top: solid 1px #34384b;
    }
  }

  &[data-type='dashboard'] {
    .footer {
      &__nav {
        border: unset;
        padding: {
          bottom: 1.25rem;
        }

        @include media-breakpoint-down('md') {
          padding: {
            top: 0.5rem;
            bottom: 0.5rem;
          }
        }
      }

      &__top {
        border-bottom: solid 1px #34384b;

        @include media-breakpoint-down('md') {
          padding: {
            top: 1.25rem;
            bottom: 1.25rem;
          }
        }
      }
    }
  }
}
