@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/button-group';

%btn-anchor {
  padding: 0;
  padding-left: 2rem;
  position: relative;
  text-align: left;
  text-decoration: none;

  @keyframes iconAnimation {
    0% {
      transform: rotate(-90deg) scale(0.9);
    }
    50% {
      transform: rotate(-90deg) scale(1.15);
    }
    100% {
      transform: rotate(-90deg) scale(0.9);
    }
  }

  &::before {
    --iconScale: 1;
    @extend %base-icon;
    content: $mi-chevron-down;
    @include font-size(0.75rem);
    color: color-yiq($coral);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background-color: $coral;
    border: solid $coral 1px;
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.1em;
    transition: transform ease-in-out 350ms;
    transform: rotate(-90deg) scale(1);
  }

  &:focus,
  &:hover {
    outline: unset;
    box-shadow: unset;
    text-decoration: underline;
    text-decoration-color: $primary;

    &::before {
      animation: iconAnimation 1s infinite;
    }
  }
}

.btn-anchor,
.btn-outline-anchor {
  @extend %btn-anchor;
}

.btn-outline-anchor {
  &::before {
    color: $coral;
    background-color: transparent;
  }
}

.btn-icon {
  padding: 0;
  text-decoration: none;
  color: currentColor;
  transition: all ease-out 150ms;

  &:focus,
  &:hover {
    box-shadow: none;
    opacity: 0.5;
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color}-icon {
    padding: 0;
    text-decoration: none;
    border: solid 1px $value;
    background-color: $value;
    color: color-yiq($value);
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    font-size: 1rem !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {
      color: $value;
      background-color: color-yiq($value);
    }

    &.btn-sm {
      padding-top: 0.1em;
      width: 1.15rem;
      height: 1.15rem;
      font-size: 0.6rem !important;
    }
  }

  .btn-rounded-#{$color} {
    @include button-variant($value, $value);
    border-radius: $btn-padding-x;

    @if $color == 'white' {
      border-color: darken($value, 15%);
    }
  }
}
