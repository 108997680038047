@import 'scss/for-module';

.wrapper {
  --translate-y-searchBar: -100%;
  position: fixed;
  z-index: $zindex-modal-backdrop;
  background-color: $body-bg;
  height: var(--sidebar-size, 5rem);
  top: 0;
  right: 0;
  left: var(--header-left, var(--sidebar-size, 0));
  overflow-y: hidden;
  transform: translateY(var(--translate-y-searchBar, 0%));
  transition: transform cubic-bezier(0.23, 1, 0.32, 1) 250ms, height ease-in 450ms;

  &.open {
    --translate-y-searchBar: 0%;
  }

  &.expand {
    height: 100%;

    .close {
      top: 1.5rem;
    }

    .wrapper__container {
      padding-top: var(--sidebar-size, 2rem);
    }
  }

  .close {
    color: $danger;
    position: absolute;
    top: 1.1rem;
    right: 2rem;

    &:hover,
    &:focus {
      color: $black;
      opacity: 1;
    }
  }

  &__container {
    transition: all ease-in-out 300ms;
    transition-delay: 50ms;

    .searchInput {
      margin-top: 0.85rem;
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }
}
