@import 'scss/for-module';

.table {
  width: 100%;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;

  td,
  th {
    border: unset !important;
    vertical-align: middle !important;

    @include media-breakpoint-down('md') {
      border-right: solid 1px $grey !important;

      &:last-child {
        border-right: unset !important;
      }
    }
  }

  th {
    background-color: $white;

    .table__action__wrapper {
      display: inline-block;
      float: right;
    }
  }

  td {
    background-color: $body-bg;
  }

  &__row {
    &.head,
    &:not(:last-child) {
      border-bottom: solid 1px $grey;
    }

    &.head {
      th {
        vertical-align: middle;
        font-weight: 600;
      }
    }

    &__checked {
      --bg-color: #caeed9;
      background-color: var(--bg-color);

      &:hover {
        background-color: var(--bg-color);
      }
    }
  }

  &__cell {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: unset;
  }

  &__action {
    text-align: right;

    &__menu {
      top: -100% !important;
      max-height: 18rem !important;
    }

    &__button {
      font-size: 10px;
      color: #282b3e;

      .dots {
        display: block;
        width: max-content;
        margin-left: auto;
        font-size: 10px;

        @include media-breakpoint-down('sm') {
          font-size: 0.65em;
        }
      }

      &::after {
        content: unset !important;
      }

      &__shown {
        .dots {
          transform: rotate(90deg) translateY(-0.65rem);

          @include media-breakpoint-down('sm') {
            font-size: 0.8em;
          }
        }
      }
    }

    &__wrapper {
      // display: inline-block;
    }
  }
}

.mobile {
  width: calc(100vw + 15px);
  max-width: 100vw;
  overflow-x: auto;
  padding: {
    left: 15px;
    right: 15px;
  }
  margin: {
    left: -15px;
    right: -15px;
  }

  .table {
    width: max-content;

    tr {
      position: relative;
    }

    th,
    td {
      &:first-child {
        left: 0;
        position: sticky;
        max-width: 15rem;
        transition: all ease-in-out 0.25s;
      }
    }
  }

  &.scrolled {
    .table {
      th,
      td {
        &:first-child {
          max-width: 10rem;
          left: -15px;

          &::after {
            content: '';
            position: absolute;
            height: 100%;
            width: 1px;
            right: -1px;
            bottom: 0;
            top: 0;
            box-shadow: 5px 0 9px 0px rgba(0, 0, 0, 0.5);
            background-color: #cccccc;
          }
        }
      }
    }
  }
}
