@import '~react-notifications-component/dist/scss/variables';

@keyframes timer {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

.flex-center {
  min-width: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: all;
}

.notification {
  @extend .shadow-lg;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.notifications-component {
  position: fixed;
  z-index: 9000;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.notification__item {
  width: 100%;
  display: flex;
  background-color: $body-bg;
  color: color-yiq($body-bg);
  position: relative;
  cursor: pointer;
}

.notification__item--default {
  border-left: 8px solid $default_dark;

  //   .notification__timer {
  //     background-color: $default_timer;
  //   }
  //   .notification__timer-filler {
  //     background-color: $default_timer_filler;
  //   }
}

@each $color, $value in $theme-colors {
  .notification__item--#{$color} {
    border-left: 8px solid $value;

    // .notification__timer {
    //   background-color: $default_timer;
    // }

    // .notification__timer-filler {
    //   background-color: $default_timer_filler;
    // }
  }
}

.notification__timer {
  width: 100%;
  height: 3px;
  margin-top: 10px;
  border-radius: 5px;
}

.notification__timer-filler {
  height: 3px;
  border-radius: 5px;
}

.notification__title {
  //   margin-top: 5px;
  //   margin-bottom: 5px;
}

.notification__message {
  max-width: calc(100% - 15px);
  font-size: 0.85em;
  word-wrap: break-word;
  //   margin-bottom: 5px;
  //   margin-top: 0;
}

.notification__icon {
  padding: 1.25rem 0rem 1.25rem 1rem;

  [class^='mi-'] {
    font-size: 2.25em;
  }
}

.notification__content {
  padding: 1.25rem 1rem;
  display: inline-block;
  width: 100%;
}

.notification__close {
  @extend %base-icon;
  @extend .mi-cross;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 1rem;
  top: 1.25rem;
}

.notification-container {
  &--top-center,
  &--top-left,
  &--top-right,
  &--bottom-center,
  &--bottom-left,
  &--bottom-right,
  &--center,
  &--top-full,
  &--bottom-full {
    min-width: 100vw;
    position: absolute;
    pointer-events: all;
  }

  &--center,
  &--top-center,
  &--bottom-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: calc(50% - 175px);
    max-width: 350px;
  }

  &--center {
    top: 20px;
    height: 100%;
    pointer-events: none;
  }

  &--top-full,
  &--bottom-full {
    width: 100%;
    min-width: 100%;
  }

  &--bottom-full {
    bottom: 0;
  }

  &--top-center {
    top: 20px;
  }
  &--bottom-center {
    bottom: 20px;
  }

  &--top-left {
    left: 20px;
    top: 20px;
  }

  &--top-right {
    right: 20px;
    top: 20px;
  }

  &--bottom-left {
    left: 20px;
    bottom: 20px;
  }

  &--bottom-right {
    bottom: 20px;
    right: 20px;
  }

  &--mobile-top,
  &--mobile-bottom {
    pointer-events: all;
    position: absolute;
  }

  &--mobile-top {
    right: 20px;
    left: 20px;
    top: 20px;
  }

  &--mobile-bottom {
    right: 20px;
    left: 20px;
    bottom: 20px;
    margin-bottom: -15px;
  }

  &--top-full .notification__item,
  &--bottom-full .notification__item {
    margin-bottom: 0;
    border-radius: 0;
  }

  &--mobile-top .notification__item,
  &--mobile-bottom .notification__item,
  &--mobile-top .notification,
  &--mobile-bottom .notification {
    max-width: 100%;
    width: 100%;
  }

  &--top-right .notification,
  &--bottom-right .notification {
    margin-left: auto;
  }

  &--top-left .notification,
  &--bottom-left .notification {
    margin-right: auto;
  }

  &--mobile-top .notification,
  &--mobile-bottom .notification {
    margin-left: auto;
    margin-right: auto;
  }
}
