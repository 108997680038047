@import 'scss/for-module';

.dots {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    a {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 0.5rem;
      background-color: $white;
      opacity: 0.5;
    }

    &.active {
      a {
        opacity: 1;
      }
    }
  }
}
