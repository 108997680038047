@import 'scss/for-module';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.subheading {
  max-width: 32rem;
  font-weight: 300;
}

.form {
  width: 100%;
  max-width: 32rem;
  margin-top: 2rem;
  margin-bottom: auto;
}
