@import 'scss/for-module';

.sidebar {
  $size: var(--sidebar-size, 5rem);
  $childSize: var(--sidebar-child-size, 15rem);
  $background: $brightGray;
  $color: color-yiq($background);
  background-color: $background;
  color: $color;
  width: $size;
  height: 100vh;
  left: 0;
  top: 0;
  justify-content: flex-start;
  z-index: $zindex-modal + 1;

  &__brand {
    color: inherit;
    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    @include transition($btn-transition);

    &:hover,
    &:focus {
      color: $color;
      // background-color: darken($primary, 5%);
    }

    &__active {
      & + .sidebar__nav {
        i[class^='mi-'] {
          opacity: 1;
        }
      }
    }
  }

  &__nav {
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: column;
    background-color: $background;

    &__link {
      --bg-opacity: 0;
      display: flex !important;
      padding: 0 !important;
      max-width: 100%;
      color: inherit;
      text-decoration: none;
      flex: 1 auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: $size;
      max-height: $size;
      background-color: rgba($ebonyClay, var(--bg-opacity));
      transition: all ease-in 120ms;

      i[class^='mi-'] {
        font-size: 1.5rem;
        opacity: 0.4;
        transition: opacity ease-in 120ms;
        margin-bottom: 0.15em;
      }

      &.has-active,
      &:hover,
      &:focus {
        --bg-opacity: 1;
        color: inherit !important;

        i[class^='mi-'] {
          opacity: 1;
        }
      }
    }

    &__item {
      &__label {
        font-size: 0.825rem;
        font-weight: 300;
      }
    }

    li {
      border-bottom: solid 1px $darkNavy;
      display: flex;
    }
  }

  &__childNav {
    font-size: 0.9rem;
    position: fixed;
    width: $childSize;
    left: $size;
    top: 0;
    bottom: 0;
    background-color: $white;
    z-index: $zindex-modal-backdrop - 1;
    border-right: solid 1px $grey;

    li {
      width: 100%;
      border-bottom: solid 1px $grey;
      display: flex;
    }

    &__brand {
      box-shadow: inset 0px -1px 0px $grey;
    }

    &__link {
      position: relative;
      display: flex !important;
      align-items: center;
      justify-content: flex-start;
      color: $black;
      width: 100%;
      height: $size;
      @include transition($btn-transition);
      padding: {
        left: calc(#{$size / 3}) !important;
        right: calc(#{$size / 3}) !important;
      }

      &.has-active,
      &:hover,
      &:focus {
        background-color: $body-bg;
      }

      &.has-active {
        &::before {
          content: '';
          position: absolute;
          width: 0.25rem;
          height: 100%;
          top: 0;
          left: 0;
          background-color: $primary;
        }
      }
    }
  }
}
