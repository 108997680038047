@import 'scss/for-module';

.billboard {
  background-color: $primary !important;
  color: $white !important;
  margin-bottom: 2.5rem;

  .wrapper {
    @include media-breakpoint-down('lg') {
      max-width: 85%;
    }

    @include media-breakpoint-up('xl') {
      padding: {
        left: 1rem;
        right: 1rem;
        top: 0.75rem;
        bottom: 0.75rem;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include media-breakpoint-down('lg') {
        margin: {
          top: 1.5rem;
          bottom: 1.5rem;
        }
      }
    }

    h1 {
      @include media-breakpoint-down('lg') {
        br {
          display: none;
        }
      }
    }
  }

  .heading {
    display: block;
    margin-bottom: 0;
    color: $white;
    font-weight: 600;
    font-size: 1.5rem;
    word-break: break-all;

    span {
      border-bottom: solid 2px;
    }
  }
}
