@import 'scss/for-module';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.subheading {
  max-width: 32rem;
  font-weight: 300;
}

.otpInput {
  display: inline-block;
  max-width: 7.25rem;
  margin: 0 !important;

  input {
    font-size: 0.9em;
    letter-spacing: 0.05em;
    padding: 0 !important;
    text-align: center;
    height: auto;
  }
}
