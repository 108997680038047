@import '~bootstrap/scss/modal';

.modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.modal-header {
  border-bottom: unset;
  padding: $modal-header-padding;
}

.modal-footer {
  border-top: unset;
  padding: $modal-inner-padding - $modal-footer-margin-between / 2;
  justify-content: flex-start;
}
