.wrapper {
  $ecard-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  width: 309px;
  height: 189px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: $ecard-shadow;

  &.bordered {
    border: 1.5px dashed #c6c7d0;
  }
}
