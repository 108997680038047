@font-face {
  font-family: 'Manulife JH Sans';
  src: url('../../assets/fonts/ManulifeJHSans-Bold.eot');
  src: url('../../assets/fonts/ManulifeJHSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/ManulifeJHSans-Bold.woff2') format('woff2'),
    url('../../assets/fonts/ManulifeJHSans-Bold.woff') format('woff'),
    url('../../assets/fonts/ManulifeJHSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manulife JH Sans';
  src: url('../../assets/fonts/ManulifeJHSans-Demibold.eot');
  src: url('../../assets/fonts/ManulifeJHSans-Demibold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/ManulifeJHSans-Demibold.woff2') format('woff2'),
    url('../../assets/fonts/ManulifeJHSans-Demibold.woff') format('woff'),
    url('../../assets/fonts/ManulifeJHSans-Demibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manulife JH Sans';
  src: url('../../assets/fonts/ManulifeJHSans-Light.eot');
  src: url('../../assets/fonts/ManulifeJHSans-Light.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/ManulifeJHSans-Light.woff2') format('woff2'),
    url('../../assets/fonts/ManulifeJHSans-Light.woff') format('woff'),
    url('../../assets/fonts/ManulifeJHSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manulife JH Sans';
  src: url('../../assets/fonts/ManulifeJHSans-Regular.eot');
  src: url('../../assets/fonts/ManulifeJHSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/ManulifeJHSans-Regular.woff2') format('woff2'),
    url('../../assets/fonts/ManulifeJHSans-Regular.woff') format('woff'),
    url('../../assets/fonts/ManulifeJHSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manulife JH Serif';
  src: url('../../assets/fonts/ManulifeJHSerif-Italic.eot');
  src: url('../../assets/fonts/ManulifeJHSerif-Italic.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/ManulifeJHSerif-Italic.woff2') format('woff2'),
    url('../../assets/fonts/ManulifeJHSerif-Italic.woff') format('woff'),
    url('../../assets/fonts/ManulifeJHSerif-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src: url('../../assets/fonts/icons/icomoon.eot?cqti77');
  src: url('../../assets/fonts/icons/icomoon.eot?cqti77#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icons/icomoon.ttf?cqti77') format('truetype'),
    url('../../assets/fonts/icons/icomoon.woff?cqti77') format('woff'),
    url('../../assets/fonts/icons/icomoon.svg?cqti77#icomoon') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
