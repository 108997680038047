@import 'scss/for-module';

.label {
  line-height: inherit;
  display: flex;
  align-items: center;

  .dot {
    $size: 0.75em;
    width: $size;
    height: $size;
    background: currentColor;
    display: inline-block;
    border-radius: $size;
    margin-right: $size / 2;
  }
}
