@import '~bootstrap/scss/reboot';

@mixin reset-text-white {
  &.text-white {
    &:hover {
      color: currentColor !important;
    }
  }
}

// Links
a {
  position: relative;
  color: $link-color;
  text-decoration: unset;
  // text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
  }

  &.decoration-underlined {
    &::after {
      content: '';
      position: absolute;
      height: 0px;
      width: 100%;
      left: 0;
      bottom: -0.1em;
      border: solid 1px $link-decoration-color;
    }

    &:hover {
      &::after {
        content: '';
        border-width: 0px;
      }
    }

    &.-current {
      &::after {
        content: '';
        border-color: currentColor;
      }

      &:hover {
        color: currentColor;
      }
    }
  }
}

b,
strong {
  font-weight: $font-weight-bold;
}
