@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/input-group';

%label {
  color: #b1bbc4;
  font-size: 1rem;
  font-weight: normal;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}

%label-grow {
  font-size: 0.85rem;
  font-weight: 400;
  pointer-events: auto;
}

.label-grow {
  padding-left: 5px;
  @extend %label;
  @extend %label-grow;
}

.form-group {
  position: relative;
  margin-top: 1.2rem;
  margin-bottom: 1.55rem;

  label {
    &:not(.form-check-label) {
      position: absolute;
      left: 5px;
      top: 0.35rem;
      @extend %label;
    }
  }

  .form-control {
    border: none;
    border-radius: 0px;
    background: transparent;
    padding-left: 5px;
    box-shadow: none;

    &.underlined {
      border-bottom-width: 2px;
      border-bottom-color: #b1bbc4;

      &--solid {
        border-bottom-style: solid;
      }

      &--dashed {
        border-bottom-style: dashed;
      }
    }

    &.has-grow {
      color: #000;

      & ~ label {
        top: -18px;
        @extend %label-grow;
      }

      &:focus {
        & ~ label {
          color: $primary;
        }
      }

      &.is-valid {
        border-bottom-color: $success;
      }
    }

    &::placeholder {
      color: #b1bbc4;
    }

    &:invalid,
    &.is-invalid {
      border-bottom-color: $danger;

      &:focus {
        border-bottom-color: $danger;
      }
    }

    &:focus,
    &[aria-expanded='true'] {
      border-bottom-color: $primary;
      box-shadow: unset !important;
    }

    &[type='number'] {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .input-group {
    &.dropdown-toggle {
      .caret {
        @extend %base-icon;
        @extend .mi-chevron-down;
        @include font-size($input-font-size * 0.85);
        padding: #{$input-padding-y * 1.8} 0;
        line-height: $input-line-height;
        pointer-events: none;
        position: absolute;
        color: $black;
        right: 5px;
        top: 0;
      }

      .form-control {
        cursor: pointer;
      }

      &::after {
        content: unset;
      }
    }

    &-append,
    &-prepend {
      padding: #{$input-padding-y} 0;
      position: absolute;
      top: 0;

      .input-group-text {
        border: unset;
        background: unset;
        color: currentColor;
      }

      .icon {
        padding: 0;
        cursor: pointer;
        z-index: 10;

        &--as-help {
          cursor: help;
        }

        .mi,
        [class^='mi-'] {
          @include font-size($input-font-size * 1.35);
          line-height: $input-line-height;

          &.lg {
            @include font-size($input-font-size * 1.65);
          }
        }
      }
    }

    &-append {
      right: 0;
    }

    &-prepend {
      left: 0;
      bottom: 0;

      & + .form-control {
        padding-left: 2rem;

        & ~ label {
          left: 2rem;
        }

        &.has-grow {
          & ~ label {
            left: 0rem;
          }
        }
      }
    }
  }

  .form-check-label {
    z-index: 1;
    display: block;
    cursor: pointer;
    position: relative;
    min-height: 1.45rem;
    color-adjust: exact;
    margin-top: 0.45rem;
    padding-left: 2.25rem;

    %checkbox-indicator {
      display: inline-block;
      position: absolute;
      top: -3px;
      left: 0;
      width: 1.75rem;
      height: 1.75rem;
      border: 2px solid #b1bbc4;
    }

    %radio-indicator {
      @extend %checkbox-indicator;
      border-radius: 100%;
    }

    .form-check-input {
      display: none;

      &[type='checkbox'] {
        & ~ .indicator {
          @extend %checkbox-indicator;
          border-top: 0px solid transparent;
          border-left: 0px solid transparent;
          transition-property: border-color, border-width, transform, width, left, top;
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1), ease-in, ease-in, ease, ease,
            ease;
          transition-duration: 75ms, 200ms, 300ms, 350ms, 200ms, 200ms;
          transition-delay: 0ms, 150ms, 0ms, 0ms, 0ms, 0ms;

          &__back {
            @extend %checkbox-indicator;
            transition: 0.2s all ease-in;
            transition-delay: 0.2s;
            z-index: -1;
          }
        }

        &:checked ~ .indicator {
          width: 1rem;
          left: 0.15rem;
          border-width: 4px;
          border-right-color: $primary;
          border-bottom-color: $primary;
          transform: rotateZ(45deg) translate(1px, -5px) scale(0.5);

          &__back {
            border-color: $primary;
            background: $white;
          }
        }
      }

      &[type='radio'] {
        & ~ .indicator {
          @extend %radio-indicator;
          transition-property: border-color, background-color, transform;
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1), ease-in, ease-in;
          transition-duration: 75ms, 200ms, 300ms;
          transition-delay: 0ms, 50ms, 0ms;

          &__back {
            @extend %radio-indicator;
            transition: 0.2s all ease-in;
            transition-delay: 0.2s;
            z-index: -1;
          }
        }

        &:checked ~ .indicator {
          transform: scale(0.35);
          background-color: $primary;
          border-color: $primary;

          &__back {
            border-color: $primary;
            background: $white;
          }
        }
      }
    }

    &.top-0 {
      .indicator {
        top: 0 !important;

        &__back {
          top: 0 !important;
        }
      }
    }

    @each $color, $value in $theme-colors {
      &.-#{$color} {
        .form-check-input {
          &[type='checkbox'] {
            &:checked ~ .indicator {
              border-right-color: $value;
              border-bottom-color: $value;

              &__back {
                border-color: $value;
              }
            }
          }

          &[type='radio'] {
            &:checked ~ .indicator {
              background-color: $value;
              border-color: $value;

              &__back {
                border-color: $value;
              }
            }
          }
        }
      }
    }
  }

  &.has-validation {
    .valid-feedback,
    .invalid-feedback {
      padding-left: 5px;
    }
  }

  &.has-percentage {
    .form-control {
      width: auto;
      max-width: 8.5rem;
      padding-right: 0.25rem;
      font-size: 2.25rem;

      @include media-breakpoint-down('sm') {
        max-width: 6.75rem;
        font-size: 1.95rem;
        padding-bottom: 0;
      }
    }

    .input-group {
      position: relative;

      &-append,
      &-prepend {
        position: relative;

        .icon {
          font-size: 2.25rem;
        }

        @include media-breakpoint-down('sm') {
          padding-top: 1em;

          .icon {
            font-size: 1.5rem;
          }
        }
      }

      .form-control {
        font-weight: 500;
      }

      // .back {
      //   top: 0.45rem;
      //   left: 4px;
      //   z-index: 9;
      //   position: absolute;
      //   overflow: hidden;
      //   font-weight: 600;
      //   max-width: 8.5rem;
      //   font-size: 2.25rem;
      //   pointer-events: none;
      //   width: auto;

      //   @include media-breakpoint-down('sm') {
      //     top: 0.64rem;
      //     left: 4.5px;
      //     max-width: 6.75rem;
      //     font-size: 1.95rem;
      //   }
      // }
    }
  }
}

.select-menu {
  &.dropdown-menu {
    padding: 0;
    max-height: 12rem;
    overflow-y: auto;
    min-width: 3rem;
    @include box-shadow($popover-box-shadow);
  }

  &__list {
    @extend .list-unstyled;
    margin-bottom: 0;

    .dropdown-item {
      padding: 0.75rem 1.25rem;

      &:not(:first-child) {
        border-top: solid 1px #ededed;
      }

      &.active,
      &:active {
        color: $black;
        background-color: rgba($primary, 0.2);
      }
    }
  }
}
