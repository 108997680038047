@import 'scss/for-module';

.wrapper {
  flex: 1 auto;
}

.input {
  &__wrapper {
    width: auto !important;

    @include media-breakpoint-up('lg') {
      min-width: 17rem;
      max-width: 17rem !important;
    }

    @include media-breakpoint-up('xxl') {
      min-width: 15rem;
      max-width: 15rem !important;
    }
  }
}

.summaryCard {
  @include media-breakpoint-up('md') {
    max-width: 16rem;
  }
}

.subheading {
  max-width: 48rem;
  font-weight: 300;
}

.cardLink {
  width: 100%;
  height: 10rem;
  background-color: $white;
  text-align: center;
  text-decoration: none;

  [class^='card-body'] {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
}

.otpInput {
  display: inline-block;
  max-width: 7.25rem;
  margin: 0 !important;

  input {
    font-size: 0.9em;
    letter-spacing: 0.05em;
    padding: 0 !important;
    text-align: center;
    height: auto;
  }
}
